define('ember-model-validator/mixins/object-validator', ['exports', 'ember', 'ember-data', 'ember-model-validator/mixins/model-validator'], function (exports, _ember, _emberData, _emberModelValidatorMixinsModelValidator) {
  exports['default'] = _ember['default'].Mixin.create(_emberModelValidatorMixinsModelValidator['default'], {
    errors: _emberData['default'].Errors.create(),

    clearErrors: function clearErrors() {
      this.set('errors', _emberData['default'].Errors.create());
    },

    pushErrors: function pushErrors(errors) {
      for (var attribute in errors) {
        var messages = errors[attribute];
        this.get('errors').add(attribute, messages);
      }
    },
    _modelRelations: function _modelRelations() {}
  });
});