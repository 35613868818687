define('ember-toastr/services/toast', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;

  var proxyGenerator = function proxyGenerator(name) {
    return function () {
      var msg = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];
      var title = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var toasts = this.get('toasts');
      var toast = window.toastr[name](msg.toString(), title.toString(), options);

      if (toast) {
        toasts.pushObject(toast);
      }

      return toast;
    };
  };

  exports['default'] = _ember['default'].Service.extend({
    success: proxyGenerator('success'),
    info: proxyGenerator('info'),
    warning: proxyGenerator('warning'),
    error: proxyGenerator('error'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.toasts = _ember['default'].A([]);

      // Auto remove toasts when hidden
      window.toastr.options.onHidden = run.bind(this, function () {
        var toasts = _this.get('toasts');
        var notVisible = toasts.filter(function (item) {
          return !item.is(':visible');
        });
        toasts.removeObjects(notVisible);
      });
    },

    clear: function clear(toastElement) {
      window.toastr.clear(toastElement);
      if (toastElement) {
        this.get('toasts').removeObject(toastElement);
      } else {
        this.set('toasts', _ember['default'].A([]));
      }
    },

    remove: function remove(toastElement) {
      if (toastElement) {
        this.get('toasts').removeObject(toastElement);
        toastElement.remove();
      } else {
        this.set('toasts', _ember['default'].A([]));
      }
      window.toastr.remove(toastElement);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.remove();
    }
  });
});