define('ember-math-helpers/helpers/cos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cos = cos;
  var helper = Ember.Helper.helper;
  function cos(params) {
    return Math.cos(params[0]);
  }

  exports.default = helper(cos);
});