define('ember-leaflet-marker-cluster/components/marker-cluster-layer', ['exports', 'ember-leaflet/components/base-layer', 'ember-composability-tools', 'ember-leaflet-marker-cluster/templates/marker-cluster-layer'], function (exports, _emberLeafletComponentsBaseLayer, _emberComposabilityTools, _emberLeafletMarkerClusterTemplatesMarkerClusterLayer) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberLeafletComponentsBaseLayer['default'].extend(_emberComposabilityTools.ParentMixin, {
    layout: _emberLeafletMarkerClusterTemplatesMarkerClusterLayer['default'],

    leafletOptions: ['showCoverageOnHover', 'zoomToBoundsOnClick', 'spiderfyOnMaxZoom', 'removeOutsideVisibleBounds', 'animate', 'animateAddingMarkers', 'disableClusteringAtZoom', 'maxClusterRadius', 'polygonOptions', 'singleMarkerMode', 'spiderLegPolylineOptions', 'spiderfyDistanceMultiplier', 'iconCreateFunction'],

    leafletEvents: [
    // Marker clutster events
    'clusterclick', 'clusterdblclick', 'clustermousedown', 'clustermouseover', 'clustermouseout', 'clustercontextmenu', 'clusteradd', 'clusterremove', 'animationend', 'spiderfied', 'unspiderfied',
    // Marker events
    'click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'dragstart', 'drag', 'dragend', 'move', 'remove', 'add', 'popupopen', 'popupclose'],

    createLayer: function createLayer() {
      var _L;

      return (_L = this.L).markerClusterGroup.apply(_L, _toConsumableArray(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });
});