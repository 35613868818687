define('ember-math-helpers/helpers/floor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.floor = floor;
  var helper = Ember.Helper.helper;
  function floor(params) {
    return Math.floor(params[0]);
  }

  exports.default = helper(floor);
});