define('ember-simple-auth-auth0/utils/get-session-expiration', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getSessionExpiration;
  var get = _ember.default.get,
      getWithDefault = _ember.default.getWithDefault;


  /**
   * Get the token expiration time from the specified session data object.
   * If an ID token is defined, use the `exp` field since it's nice and
   * well-defined. Otherwise, calculate an expiration time from the
   * expiresIn field and the time the session object was created.
   * 
   * @return {Expiration time of token (Unix timestamp)}
   */
  function getSessionExpiration(sessionData) {
    var idTokenExpiration = get(sessionData, 'idTokenPayload.exp');

    if (idTokenExpiration) {
      return idTokenExpiration;
    } else {
      var issuedAt = getWithDefault(sessionData, 'issuedAt', 0);
      var expiresIn = getWithDefault(sessionData, 'expiresIn', 0);

      return issuedAt + expiresIn;
    }
  }
});