define('ember-math-helpers/helpers/tan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tan = tan;
  var helper = Ember.Helper.helper;
  function tan(params) {
    return Math.tan(params[0]);
  }

  exports.default = helper(tan);
});