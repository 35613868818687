define('ember-cli-string-helpers/utils/uppercase', ['exports'], function (exports) {
  exports['default'] = uppercase;

  function uppercase() {
    var string = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

    if (typeof string !== 'string') {
      throw new TypeError('Expected a string, got a ' + typeof string);
    }

    return string.toUpperCase();
  }
});