define("ember-data-model-fragments/util/instance-of-type", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isInstanceOfType;

  // Check whether a object is an instance of the given type, respecting model
  // factory injections
  function isInstanceOfType(type, obj) {
    return obj instanceof type;
  }
});