define(
    "d3-interpolate",
    ["exports", "d3-color"],
    function (exports,d3Color) { 'use strict';

      function basis(t1, v0, v1, v2, v3) {
        var t2 = t1 * t1, t3 = t2 * t1;
        return ((1 - 3 * t1 + 3 * t2 - t3) * v0
            + (4 - 6 * t2 + 3 * t3) * v1
            + (1 + 3 * t1 + 3 * t2 - 3 * t3) * v2
            + t3 * v3) / 6;
      }

      function basis$1(values) {
        var n = values.length - 1;
        return function(t) {
          var i = t <= 0 ? (t = 0) : t >= 1 ? (t = 1, n - 1) : Math.floor(t * n),
              v1 = values[i],
              v2 = values[i + 1],
              v0 = i > 0 ? values[i - 1] : 2 * v1 - v2,
              v3 = i < n - 1 ? values[i + 2] : 2 * v2 - v1;
          return basis((t - i / n) * n, v0, v1, v2, v3);
        };
      }

      function basisClosed(values) {
        var n = values.length;
        return function(t) {
          var i = Math.floor(((t %= 1) < 0 ? ++t : t) * n),
              v0 = values[(i + n - 1) % n],
              v1 = values[i % n],
              v2 = values[(i + 1) % n],
              v3 = values[(i + 2) % n];
          return basis((t - i / n) * n, v0, v1, v2, v3);
        };
      }

      function constant(x) {
        return function() {
          return x;
        };
      }

      function linear(a, d) {
        return function(t) {
          return a + t * d;
        };
      }

      function exponential(a, b, y) {
        return a = Math.pow(a, y), b = Math.pow(b, y) - a, y = 1 / y, function(t) {
          return Math.pow(a + t * b, y);
        };
      }

      function hue(a, b) {
        var d = b - a;
        return d ? linear(a, d > 180 || d < -180 ? d - 360 * Math.round(d / 360) : d) : constant(isNaN(a) ? b : a);
      }

      function gamma(y) {
        return (y = +y) === 1 ? nogamma : function(a, b) {
          return b - a ? exponential(a, b, y) : constant(isNaN(a) ? b : a);
        };
      }

      function nogamma(a, b) {
        var d = b - a;
        return d ? linear(a, d) : constant(isNaN(a) ? b : a);
      }

      var rgb$1 = (function rgbGamma(y) {
        var color = gamma(y);

        function rgb(start, end) {
          var r = color((start = d3Color.rgb(start)).r, (end = d3Color.rgb(end)).r),
              g = color(start.g, end.g),
              b = color(start.b, end.b),
              opacity = color(start.opacity, end.opacity);
          return function(t) {
            start.r = r(t);
            start.g = g(t);
            start.b = b(t);
            start.opacity = opacity(t);
            return start + "";
          };
        }

        rgb.gamma = rgbGamma;

        return rgb;
      })(1);

      function rgbSpline(spline) {
        return function(colors) {
          var n = colors.length,
              r = new Array(n),
              g = new Array(n),
              b = new Array(n),
              i, color;
          for (i = 0; i < n; ++i) {
            color = d3Color.rgb(colors[i]);
            r[i] = color.r || 0;
            g[i] = color.g || 0;
            b[i] = color.b || 0;
          }
          r = spline(r);
          g = spline(g);
          b = spline(b);
          color.opacity = 1;
          return function(t) {
            color.r = r(t);
            color.g = g(t);
            color.b = b(t);
            return color + "";
          };
        };
      }

      var rgbBasis = rgbSpline(basis$1);
      var rgbBasisClosed = rgbSpline(basisClosed);

      function array(a, b) {
        var nb = b ? b.length : 0,
            na = a ? Math.min(nb, a.length) : 0,
            x = new Array(nb),
            c = new Array(nb),
            i;

        for (i = 0; i < na; ++i) x[i] = value(a[i], b[i]);
        for (; i < nb; ++i) c[i] = b[i];

        return function(t) {
          for (i = 0; i < na; ++i) c[i] = x[i](t);
          return c;
        };
      }

      function date(a, b) {
        var d = new Date;
        return a = +a, b -= a, function(t) {
          return d.setTime(a + b * t), d;
        };
      }

      function number(a, b) {
        return a = +a, b -= a, function(t) {
          return a + b * t;
        };
      }

      function object(a, b) {
        var i = {},
            c = {},
            k;

        if (a === null || typeof a !== "object") a = {};
        if (b === null || typeof b !== "object") b = {};

        for (k in b) {
          if (k in a) {
            i[k] = value(a[k], b[k]);
          } else {
            c[k] = b[k];
          }
        }

        return function(t) {
          for (k in i) c[k] = i[k](t);
          return c;
        };
      }

      var reA = /[-+]?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]?\d+)?/g;
      var reB = new RegExp(reA.source, "g");
      function zero(b) {
        return function() {
          return b;
        };
      }

      function one(b) {
        return function(t) {
          return b(t) + "";
        };
      }

      function string(a, b) {
        var bi = reA.lastIndex = reB.lastIndex = 0, // scan index for next number in b
            am, // current match in a
            bm, // current match in b
            bs, // string preceding current number in b, if any
            i = -1, // index in s
            s = [], // string constants and placeholders
            q = []; // number interpolators

        // Coerce inputs to strings.
        a = a + "", b = b + "";

        // Interpolate pairs of numbers in a & b.
        while ((am = reA.exec(a))
            && (bm = reB.exec(b))) {
          if ((bs = bm.index) > bi) { // a string precedes the next number in b
            bs = b.slice(bi, bs);
            if (s[i]) s[i] += bs; // coalesce with previous string
            else s[++i] = bs;
          }
          if ((am = am[0]) === (bm = bm[0])) { // numbers in a & b match
            if (s[i]) s[i] += bm; // coalesce with previous string
            else s[++i] = bm;
          } else { // interpolate non-matching numbers
            s[++i] = null;
            q.push({i: i, x: number(am, bm)});
          }
          bi = reB.lastIndex;
        }

        // Add remains of b.
        if (bi < b.length) {
          bs = b.slice(bi);
          if (s[i]) s[i] += bs; // coalesce with previous string
          else s[++i] = bs;
        }

        // Special optimization for only a single match.
        // Otherwise, interpolate each of the numbers and rejoin the string.
        return s.length < 2 ? (q[0]
            ? one(q[0].x)
            : zero(b))
            : (b = q.length, function(t) {
                for (var i = 0, o; i < b; ++i) s[(o = q[i]).i] = o.x(t);
                return s.join("");
              });
      }

      function value(a, b) {
        var t = typeof b, c;
        return b == null || t === "boolean" ? constant(b)
            : (t === "number" ? number
            : t === "string" ? ((c = d3Color.color(b)) ? (b = c, rgb$1) : string)
            : b instanceof d3Color.color ? rgb$1
            : b instanceof Date ? date
            : Array.isArray(b) ? array
            : isNaN(b) ? object
            : number)(a, b);
      }

      function round(a, b) {
        return a = +a, b -= a, function(t) {
          return Math.round(a + b * t);
        };
      }

      var degrees = 180 / Math.PI;

      var identity = {
        translateX: 0,
        translateY: 0,
        rotate: 0,
        skewX: 0,
        scaleX: 1,
        scaleY: 1
      };

      function decompose(a, b, c, d, e, f) {
        var scaleX, scaleY, skewX;
        if (scaleX = Math.sqrt(a * a + b * b)) a /= scaleX, b /= scaleX;
        if (skewX = a * c + b * d) c -= a * skewX, d -= b * skewX;
        if (scaleY = Math.sqrt(c * c + d * d)) c /= scaleY, d /= scaleY, skewX /= scaleY;
        if (a * d < b * c) a = -a, b = -b, skewX = -skewX, scaleX = -scaleX;
        return {
          translateX: e,
          translateY: f,
          rotate: Math.atan2(b, a) * degrees,
          skewX: Math.atan(skewX) * degrees,
          scaleX: scaleX,
          scaleY: scaleY
        };
      }

      var cssNode;
      var cssRoot;
      var cssView;
      var svgNode;
      function parseCss(value) {
        if (value === "none") return identity;
        if (!cssNode) cssNode = document.createElement("DIV"), cssRoot = document.documentElement, cssView = document.defaultView;
        cssNode.style.transform = value;
        value = cssView.getComputedStyle(cssRoot.appendChild(cssNode), null).getPropertyValue("transform");
        cssRoot.removeChild(cssNode);
        value = value.slice(7, -1).split(",");
        return decompose(+value[0], +value[1], +value[2], +value[3], +value[4], +value[5]);
      }

      function parseSvg(value) {
        if (value == null) return identity;
        if (!svgNode) svgNode = document.createElementNS("http://www.w3.org/2000/svg", "g");
        svgNode.setAttribute("transform", value);
        if (!(value = svgNode.transform.baseVal.consolidate())) return identity;
        value = value.matrix;
        return decompose(value.a, value.b, value.c, value.d, value.e, value.f);
      }

      function interpolateTransform(parse, pxComma, pxParen, degParen) {

        function pop(s) {
          return s.length ? s.pop() + " " : "";
        }

        function translate(xa, ya, xb, yb, s, q) {
          if (xa !== xb || ya !== yb) {
            var i = s.push("translate(", null, pxComma, null, pxParen);
            q.push({i: i - 4, x: number(xa, xb)}, {i: i - 2, x: number(ya, yb)});
          } else if (xb || yb) {
            s.push("translate(" + xb + pxComma + yb + pxParen);
          }
        }

        function rotate(a, b, s, q) {
          if (a !== b) {
            if (a - b > 180) b += 360; else if (b - a > 180) a += 360; // shortest path
            q.push({i: s.push(pop(s) + "rotate(", null, degParen) - 2, x: number(a, b)});
          } else if (b) {
            s.push(pop(s) + "rotate(" + b + degParen);
          }
        }

        function skewX(a, b, s, q) {
          if (a !== b) {
            q.push({i: s.push(pop(s) + "skewX(", null, degParen) - 2, x: number(a, b)});
          } else if (b) {
            s.push(pop(s) + "skewX(" + b + degParen);
          }
        }

        function scale(xa, ya, xb, yb, s, q) {
          if (xa !== xb || ya !== yb) {
            var i = s.push(pop(s) + "scale(", null, ",", null, ")");
            q.push({i: i - 4, x: number(xa, xb)}, {i: i - 2, x: number(ya, yb)});
          } else if (xb !== 1 || yb !== 1) {
            s.push(pop(s) + "scale(" + xb + "," + yb + ")");
          }
        }

        return function(a, b) {
          var s = [], // string constants and placeholders
              q = []; // number interpolators
          a = parse(a), b = parse(b);
          translate(a.translateX, a.translateY, b.translateX, b.translateY, s, q);
          rotate(a.rotate, b.rotate, s, q);
          skewX(a.skewX, b.skewX, s, q);
          scale(a.scaleX, a.scaleY, b.scaleX, b.scaleY, s, q);
          a = b = null; // gc
          return function(t) {
            var i = -1, n = q.length, o;
            while (++i < n) s[(o = q[i]).i] = o.x(t);
            return s.join("");
          };
        };
      }

      var interpolateTransformCss = interpolateTransform(parseCss, "px, ", "px)", "deg)");
      var interpolateTransformSvg = interpolateTransform(parseSvg, ", ", ")", ")");

      var rho = Math.SQRT2;
      var rho2 = 2;
      var rho4 = 4;
      var epsilon2 = 1e-12;
      function cosh(x) {
        return ((x = Math.exp(x)) + 1 / x) / 2;
      }

      function sinh(x) {
        return ((x = Math.exp(x)) - 1 / x) / 2;
      }

      function tanh(x) {
        return ((x = Math.exp(2 * x)) - 1) / (x + 1);
      }

      // p0 = [ux0, uy0, w0]
      // p1 = [ux1, uy1, w1]
      function zoom(p0, p1) {
        var ux0 = p0[0], uy0 = p0[1], w0 = p0[2],
            ux1 = p1[0], uy1 = p1[1], w1 = p1[2],
            dx = ux1 - ux0,
            dy = uy1 - uy0,
            d2 = dx * dx + dy * dy,
            i,
            S;

        // Special case for u0 ≅ u1.
        if (d2 < epsilon2) {
          S = Math.log(w1 / w0) / rho;
          i = function(t) {
            return [
              ux0 + t * dx,
              uy0 + t * dy,
              w0 * Math.exp(rho * t * S)
            ];
          }
        }

        // General case.
        else {
          var d1 = Math.sqrt(d2),
              b0 = (w1 * w1 - w0 * w0 + rho4 * d2) / (2 * w0 * rho2 * d1),
              b1 = (w1 * w1 - w0 * w0 - rho4 * d2) / (2 * w1 * rho2 * d1),
              r0 = Math.log(Math.sqrt(b0 * b0 + 1) - b0),
              r1 = Math.log(Math.sqrt(b1 * b1 + 1) - b1);
          S = (r1 - r0) / rho;
          i = function(t) {
            var s = t * S,
                coshr0 = cosh(r0),
                u = w0 / (rho2 * d1) * (coshr0 * tanh(rho * s + r0) - sinh(r0));
            return [
              ux0 + u * dx,
              uy0 + u * dy,
              w0 * coshr0 / cosh(rho * s + r0)
            ];
          }
        }

        i.duration = S * 1000;

        return i;
      }

      function hsl$1(hue) {
        return function(start, end) {
          var h = hue((start = d3Color.hsl(start)).h, (end = d3Color.hsl(end)).h),
              s = nogamma(start.s, end.s),
              l = nogamma(start.l, end.l),
              opacity = nogamma(start.opacity, end.opacity);
          return function(t) {
            start.h = h(t);
            start.s = s(t);
            start.l = l(t);
            start.opacity = opacity(t);
            return start + "";
          };
        }
      }

      var hsl$2 = hsl$1(hue);
      var hslLong = hsl$1(nogamma);

      function lab$1(start, end) {
        var l = nogamma((start = d3Color.lab(start)).l, (end = d3Color.lab(end)).l),
            a = nogamma(start.a, end.a),
            b = nogamma(start.b, end.b),
            opacity = nogamma(start.opacity, end.opacity);
        return function(t) {
          start.l = l(t);
          start.a = a(t);
          start.b = b(t);
          start.opacity = opacity(t);
          return start + "";
        };
      }

      function hcl$1(hue) {
        return function(start, end) {
          var h = hue((start = d3Color.hcl(start)).h, (end = d3Color.hcl(end)).h),
              c = nogamma(start.c, end.c),
              l = nogamma(start.l, end.l),
              opacity = nogamma(start.opacity, end.opacity);
          return function(t) {
            start.h = h(t);
            start.c = c(t);
            start.l = l(t);
            start.opacity = opacity(t);
            return start + "";
          };
        }
      }

      var hcl$2 = hcl$1(hue);
      var hclLong = hcl$1(nogamma);

      function cubehelix$1(hue) {
        return (function cubehelixGamma(y) {
          y = +y;

          function cubehelix(start, end) {
            var h = hue((start = d3Color.cubehelix(start)).h, (end = d3Color.cubehelix(end)).h),
                s = nogamma(start.s, end.s),
                l = nogamma(start.l, end.l),
                opacity = nogamma(start.opacity, end.opacity);
            return function(t) {
              start.h = h(t);
              start.s = s(t);
              start.l = l(Math.pow(t, y));
              start.opacity = opacity(t);
              return start + "";
            };
          }

          cubehelix.gamma = cubehelixGamma;

          return cubehelix;
        })(1);
      }

      var cubehelix$2 = cubehelix$1(hue);
      var cubehelixLong = cubehelix$1(nogamma);

      function quantize(interpolator, n) {
        var samples = new Array(n);
        for (var i = 0; i < n; ++i) samples[i] = interpolator(i / (n - 1));
        return samples;
      }

      exports.interpolate = value;
      exports.interpolateArray = array;
      exports.interpolateBasis = basis$1;
      exports.interpolateBasisClosed = basisClosed;
      exports.interpolateDate = date;
      exports.interpolateNumber = number;
      exports.interpolateObject = object;
      exports.interpolateRound = round;
      exports.interpolateString = string;
      exports.interpolateTransformCss = interpolateTransformCss;
      exports.interpolateTransformSvg = interpolateTransformSvg;
      exports.interpolateZoom = zoom;
      exports.interpolateRgb = rgb$1;
      exports.interpolateRgbBasis = rgbBasis;
      exports.interpolateRgbBasisClosed = rgbBasisClosed;
      exports.interpolateHsl = hsl$2;
      exports.interpolateHslLong = hslLong;
      exports.interpolateLab = lab$1;
      exports.interpolateHcl = hcl$2;
      exports.interpolateHclLong = hclLong;
      exports.interpolateCubehelix = cubehelix$2;
      exports.interpolateCubehelixLong = cubehelixLong;
      exports.quantize = quantize;

      Object.defineProperty(exports, '__esModule', { value: true });

    }
);