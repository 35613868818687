define('ember-leaflet-google-mutant-layer/components/google-mutant-layer', ['exports', 'ember', 'ember-leaflet/components/base-layer'], function (exports, _ember, _emberLeafletComponentsBaseLayer) {
  var observer = _ember['default'].observer;
  exports['default'] = _emberLeafletComponentsBaseLayer['default'].extend({

    type: 'roadmap', // Possible types: sattelite, roadmap, terrain. hybrid is not really supported

    leafletRequiredOptions: ['type'],

    leafletOptions: ['attribution', 'opacity', 'maxZoom', 'minZoom', 'maxNativeZoom', 'continuousWorld', 'noWrap', 'styles', 'type'],

    leafletEvents: ['load', 'spawned'],

    leafletProperties: ['opacity'],

    TrafficLayer: false,

    TransitLayer: false,

    BicyclingLayer: false,

    KmlLayer: false,

    toggleTraffic: observer('TrafficLayer', function () {
      this.updateGoogleLayer('TrafficLayer');
    }),

    toggleTransit: observer('TransitLayer', function () {
      this.updateGoogleLayer('TransitLayer');
    }),

    toggleBicycling: observer('BicyclingLayer', function () {
      this.updateGoogleLayer('BicyclingLayer');
    }),

    toggleKml: observer('KmlLayer', function () {
      this.updateGoogleLayer('KmlLayer', this.get('KmlLayerOptions'));
    }),

    updateGoogleLayer: function updateGoogleLayer(layer, options) {
      this._layer[this.get(layer) ? 'addGoogleLayer' : 'removeGoogleLayer'](layer, options);
    },

    _spawned: function _spawned() {
      this.toggleTraffic();
      this.toggleTransit();
      this.toggleBicycling();
      this.toggleKml();
    },

    createLayer: function createLayer() {
      var options = this.get('options');
      options.type = options.type.toLowerCase();
      return this.L.gridLayer.googleMutant(options);
    }

  });
});