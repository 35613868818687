define('@zestia/ember-simple-infinite-scroller/components/infinite-scroller', ['exports', '@zestia/ember-simple-infinite-scroller/templates/components/infinite-scroller'], function (exports, _infiniteScroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var bind = Ember.run.bind;
  var debounce = Ember.run.debounce;
  var cancel = Ember.run.cancel;
  var resolve = Ember.RSVP.resolve;
  var inject = Ember.inject.service;
  var round = Math.round;
  exports.default = Component.extend({
    _infiniteScroller: inject('-infinite-scroller'),

    layout: _infiniteScroller.default,
    classNames: ['infinite-scroller'],
    classNameBindings: ['isLoading'],

    debug: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('_scrollHandler', bind(this, '_scroll'));
      this._listener().addEventListener('scroll', this.get('_scrollHandler'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._listener().removeEventListener('scroll', this.get('_scrollHandler'));
      cancel(this.get('_scrollDebounceId'));
    },


    actions: {
      loadMore: function loadMore() {
        this._loadMore();
      }
    },

    _scroll: function _scroll(e) {
      this.set('_scrollDebounceId', debounce(this, '_debouncedScroll', e, this._scrollDebounce()));
    },
    _debouncedScroll: function _debouncedScroll() {
      if (this.get('debug')) {
        this._debug();
      }

      if (this._shouldLoadMore()) {
        this._loadMore();
      }
    },
    _listener: function _listener() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller.document');
      } else if (this.get('use-element')) {
        return this.get('element').querySelector(this.get('use-element'));
      } else {
        return this.get('element');
      }
    },
    _element: function _element() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller.documentElement');
      } else if (this.get('use-element')) {
        return this.get('element').querySelector(this.get('use-element'));
      } else {
        return this.get('element');
      }
    },
    _scrollDebounce: function _scrollDebounce() {
      return this.get('scroll-debounce') || 100;
    },
    _scrollerHeight: function _scrollerHeight() {
      return this._element().clientHeight;
    },
    _scrollableHeight: function _scrollableHeight() {
      return this._element().scrollHeight;
    },
    _scrollTop: function _scrollTop() {
      return this._element().scrollTop;
    },
    _scrollerBottom: function _scrollerBottom() {
      return this._scrollableHeight() - this._scrollerHeight();
    },
    _scrollPercentage: function _scrollPercentage() {
      return round(this._scrollTop() / this._scrollerBottom() * 100);
    },
    _triggerAt: function _triggerAt() {
      return parseInt(this.get('trigger-at') || '100%', 10);
    },
    _reachedBottom: function _reachedBottom() {
      return this._scrollPercentage() >= this._triggerAt();
    },
    _shouldLoadMore: function _shouldLoadMore() {
      return this._reachedBottom() && !this.get('isLoading');
    },
    _debug: function _debug() {
      /* eslint-disable no-console */
      console.table([{
        'scroller height': this._scrollerHeight(),
        'scrollable height': this._scrollableHeight(),
        'scroll top': this._scrollTop(),
        'scroller bottom': this._scrollerBottom(),
        'scroll percentage': this._scrollPercentage(),
        'reached bottom': this._reachedBottom(),
        'should load more': this._shouldLoadMore(),
        'scroll debounce': this._scrollDebounce(),
        'trigger at': this._triggerAt()
      }]);
    },
    _loadMore: function _loadMore() {
      this.set('error', null);
      this.set('isLoading', true);
      resolve(this.get('on-load-more')()).catch(bind(this, '_loadError')).finally(bind(this, '_loadFinished'));
    },
    _loadError: function _loadError(error) {
      if (this.get('isDestroyed')) {
        return;
      }

      this.set('error', error);
    },
    _loadFinished: function _loadFinished() {
      if (this.get('isDestroyed')) {
        return;
      }

      this.set('isLoading', false);
    }
  });
});