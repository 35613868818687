define('ember-steps/components/step-manager/step', ['exports', 'ember-steps/-private/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      isBlank = Ember.isBlank;

  var layout = Ember.HTMLBars.template({
    "id": "vE4UOme7",
    "block": "{\"statements\":[[0,\"\\n\"],[6,[\"if\"],[[28,[\"isActive\"]]],null,{\"statements\":[[0,\"    \"],[18,\"default\"],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"hasInactiveState\"]]],null,{\"statements\":[[0,\"    \"],[18,\"inverse\"],[0,\"\\n  \"]],\"locals\":[]},null]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[\"inverse\",\"default\"],\"hasPartials\":false}",
    "meta": {}
  });

  exports.default = Component.extend({
    layout: layout,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var name = this.attrs.name;

      if (isBlank(name)) {
        throw new _errors.StepNameError('Name must be provided');
      }
      if (typeof name !== 'string') {
        throw new _errors.StepNameError('Name must be an immutable string');
      }

      this['register-step'](this);

      // This is a hacky fix to make the addon work on Ember.js <= 2.8. The
      // reason is that the VisibilitySupport mixin that is implemented in
      // every component requires the component to be in the DOM. In particular,
      // the line at https://github.com/emberjs/ember.js/blob/v2.8.0/packages/ember-views/lib/mixins/visibility_support.js#L46
      // is failing.
      //
      // As the component is not rendered in the DOM, we don't need any of the
      // functionality provided by VisibilitySupport. So the fix is "ok".
      if (isFunction(this._toggleVisibility)) {
        this._toggleVisibility = function () {};
      }
    },


    /**
     * Used internally to track the "active" state of the last time the attributes
     * were updated.
     *
     * @property {boolean} _wasActive
     * @private
     */
    _wasActive: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var isActive = get(this, 'isActive');
      var wasActive = this._wasActive;

      if (isActive && !wasActive && this['will-enter']) {
        this['will-enter']();
      }

      if (!isActive && wasActive && this['will-exit']) {
        this['will-exit']();
      }

      this._wasActive = isActive;
    },


    /**
     * Name used to transition to this step
     *
     * @property {string} name the name for this step
     * @public
     */
    name: null,

    /**
     * Whether this state is currently the active one
     * @property {boolean} isActive
     * @private
     */
    isActive: computed('currentStep', 'name', function () {
      return get(this, 'currentStep') === get(this, 'name');
    }),

    /**
     * Whether or not an inactive state should be displayed instead of
     * hiding the step entirely when not visible
     *
     * @property {boolean} hasInactiveState
     * @private
     */
    hasInactiveState: false,

    /**
     * @property {boolean} isVisible
     * @public
     */
    isVisible: computed('isActive', 'hasInactiveState', function () {
      return get(this, 'hasInactiveState') || get(this, 'isActive');
    })
  });


  function isFunction(obj) {
    return typeof obj === 'function' && typeof obj.nodeType !== 'number';
  }
});