define('ember-data-model-fragments/states', ['exports', 'ember', 'ember-data/-private'], function (exports, _ember, _private) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fragmentDidDirty = fragmentDidDirty;
  exports.fragmentDidReset = fragmentDidReset;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
    @module ember-data-model-fragments
  */

  var get = _ember.default.get;
  var create = Object.create || _ember.default.create;

  var didSetProperty = _private.RootState.loaded.saved.didSetProperty;
  var propertyWasReset = _private.RootState.loaded.updated.uncommitted.propertyWasReset;

  var dirtySetup = function dirtySetup(internalModel) {
    var record = internalModel._owner;
    var key = internalModel._name;

    // A newly created fragment may not have an owner yet
    if (record) {
      fragmentDidDirty(record, key, internalModel);
    }
  };

  /**
    Like `DS.Model` instances, all fragments have a `currentState` property
    that reflects where they are in the model lifecycle. However, there are much
    fewer states that a fragment can be in, since the `loading` state doesn't
    apply, `inFlight` states are no different than the owner record's, and there
    is no concept of a `deleted` state.
  
    This is the simplified hierarchy of valid states for a fragment:
  
    ```text
    * root
      * empty
      * loaded
        * created
        * saved
        * updated
    ```
  
    Note that there are no `uncommitted` sub-states because it's implied by the
    `created` and `updated` states (since there are no `inFlight` substates).
  
    @class FragmentRootState
  */
  var FragmentRootState = {
    // Include all `DS.Model` state booleans for consistency
    isEmpty: false,
    isLoading: false,
    isLoaded: false,
    isDirty: false,
    isSaving: false,
    isDeleted: false,
    isNew: false,
    isValid: true,

    didSetProperty: didSetProperty,

    propertyWasReset: function propertyWasReset() {},
    becomeDirty: function becomeDirty() {},
    rolledBack: function rolledBack() {},


    empty: {
      isEmpty: true,

      loadedData: function loadedData(internalModel) {
        internalModel.transitionTo('loaded.created');
      },
      pushedData: function pushedData(internalModel) {
        internalModel.transitionTo('loaded.saved');
      }
    },

    loaded: {
      pushedData: function pushedData(internalModel) {
        internalModel.transitionTo('saved');
      },


      saved: {
        setup: function setup(internalModel) {
          var record = internalModel._owner;
          var key = internalModel._name;

          // Abort if fragment is still initializing
          if (!record._internalModel._fragments[key]) {
            return;
          }

          // Reset the property on the owner record if no other siblings
          // are dirty (or there are no siblings)
          if (!get(record, key + '.hasDirtyAttributes')) {
            fragmentDidReset(record, key, internalModel);
          }
        },
        pushedData: function pushedData() {},
        didCommit: function didCommit() {},
        becomeDirty: function becomeDirty(internalModel) {
          internalModel.transitionTo('updated');
        }
      },

      created: {
        isDirty: true,

        isNew: true,

        setup: dirtySetup,

        didCommit: function didCommit(internalModel) {
          internalModel.transitionTo('saved');
        }
      },

      updated: {
        isDirty: true,

        setup: dirtySetup,

        propertyWasReset: propertyWasReset,

        didCommit: function didCommit(internalModel) {
          internalModel.transitionTo('saved');
        },
        rolledBack: function rolledBack(internalModel) {
          internalModel.transitionTo('saved');
        }
      }
    }
  };

  function mixin(original, hash) {
    for (var prop in hash) {
      original[prop] = hash[prop];
    }

    return original;
  }

  // Wouldn't it be awesome if this was public?
  function wireState(object, parent, name) {
    object = mixin(parent ? create(parent) : {}, object);
    object.parentState = parent;
    object.stateName = name;

    for (var prop in object) {
      if (!object.hasOwnProperty(prop) || prop === 'parentState' || prop === 'stateName') {
        continue;
      }
      if (_typeof(object[prop]) === 'object') {
        object[prop] = wireState(object[prop], object, name + '.' + prop);
      }
    }

    return object;
  }

  FragmentRootState = wireState(FragmentRootState, null, 'root');

  exports.default = FragmentRootState;
  function fragmentDidDirty(record, key, fragment) {
    if (!get(record, 'isDeleted')) {
      // Add the fragment as a placeholder in the owner record's
      // `_attributes` hash to indicate it is dirty
      record._internalModel._attributes[key] = fragment;

      record.send('becomeDirty');
    }
  }

  function fragmentDidReset(record, key) {
    // Make sure there's no entry in the owner record's
    // `_attributes` hash to indicate the fragment is dirty
    delete record._internalModel._attributes[key];

    // Don't reset if the record is new, otherwise it will enter the 'deleted' state
    // NOTE: This case almost never happens with attributes because their initial value
    // is always undefined, which is *usually* not what attributes get 'reset' to
    if (!get(record, 'isNew')) {
      record.send('propertyWasReset', key);
    }
  }
});