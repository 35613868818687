define('ember-data-model-fragments/transforms/fragment-array', ['exports', 'ember-data-model-fragments/transforms/fragment', 'ember-data-model-fragments/util/map'], function (exports, _fragment, _map) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragmentArray` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  var FragmentArrayTransform = _fragment.default.extend({
    deserialize: function deserializeFragmentArray(data) {
      var _this = this;

      if (data == null) {
        return null;
      }

      return (0, _map.default)(data, function (datum) {
        return _this.deserializeSingle(datum);
      }, this);
    },

    serialize: function serializeFragmentArray(snapshots) {
      if (!snapshots) {
        return null;
      }

      var store = this.store;

      return (0, _map.default)(snapshots, function (snapshot) {
        var serializer = store.serializerFor(snapshot.modelName);
        return serializer.serialize(snapshot);
      });
    }
  });

  exports.default = FragmentArrayTransform;
});