define('ember-simple-auth-auth0/utils/create-session-data-object', ['exports', 'ember', 'ember-simple-auth-auth0/utils/now'], function (exports, _ember, _now) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createSessionDataObject;

  var assign = _ember.default.assign || _ember.default.merge;

  function createSessionDataObject(profile, tokenInfo) {
    // assign things one at a time so it's compatible with the Ember.merge fallback
    var sessionData = { issuedAt: (0, _now.default)() };
    assign(sessionData, tokenInfo);
    assign(sessionData, { profile: profile });
    return sessionData;
  }
});