define('ember-i18n-iso-countries/index', ['exports', 'ember-i18n-iso-countries/codes', 'ember-i18n-iso-countries/langs/de', 'ember-i18n-iso-countries/langs/en', 'ember-i18n-iso-countries/langs/es', 'ember-i18n-iso-countries/langs/fi', 'ember-i18n-iso-countries/langs/fr', 'ember-i18n-iso-countries/langs/nl', 'ember-i18n-iso-countries/langs/pt', 'ember-i18n-iso-countries/langs/sv', 'ember-i18n-iso-countries/langs/ru'], function (exports, _emberI18nIsoCountriesCodes, _emberI18nIsoCountriesLangsDe, _emberI18nIsoCountriesLangsEn, _emberI18nIsoCountriesLangsEs, _emberI18nIsoCountriesLangsFi, _emberI18nIsoCountriesLangsFr, _emberI18nIsoCountriesLangsNl, _emberI18nIsoCountriesLangsPt, _emberI18nIsoCountriesLangsSv, _emberI18nIsoCountriesLangsRu) {
  exports.alpha3ToAlpha2 = alpha3ToAlpha2;
  exports.alpha2ToAlpha3 = alpha2ToAlpha3;
  exports.alpha3ToNumeric = alpha3ToNumeric;
  exports.alpha2ToNumeric = alpha2ToNumeric;
  exports.numericToAlpha3 = numericToAlpha3;
  exports.numericToAlpha2 = numericToAlpha2;
  exports.toAlpha3 = toAlpha3;
  exports.toAlpha2 = toAlpha2;
  exports.getName = getName;
  exports.getNames = getNames;
  exports.getAlpha2Code = getAlpha2Code;
  exports.getAlpha2Codes = getAlpha2Codes;
  exports.getAlpha3Codes = getAlpha3Codes;
  exports.getNumericCodes = getNumericCodes;

  var langs = {
    "de": _emberI18nIsoCountriesLangsDe['default'],
    "en": _emberI18nIsoCountriesLangsEn['default'],
    "fr": _emberI18nIsoCountriesLangsFr['default'],
    "nl": _emberI18nIsoCountriesLangsNl['default'],
    "sv": _emberI18nIsoCountriesLangsSv['default'],
    "es": _emberI18nIsoCountriesLangsEs['default'],
    "pt": _emberI18nIsoCountriesLangsPt['default'],
    "fi": _emberI18nIsoCountriesLangsFi['default'],
    "ru": _emberI18nIsoCountriesLangsRu['default']
  };

  /*
   * All codes map to ISO 3166-1 alpha-2
   */
  var alpha2 = {};
  var alpha3 = {};
  var numeric = {};
  var invertedNumeric = {};

  _emberI18nIsoCountriesCodes.CODES.forEach(function (codeInformation) {
    var s = codeInformation;
    alpha2[s[0]] = s[1];
    alpha3[s[1]] = s[0];
    numeric[parseInt(s[2], 10)] = s[0];
    invertedNumeric[s[0]] = parseInt(s[2], 10);
  });

  /*
   * @param code Alpha-3 code
   * @return Alpha-2 code or undefined
   */

  function alpha3ToAlpha2(code) {
    return alpha3[code];
  }

  /*
   * @param code Alpha-2 code
   * @return Alpha-3 code or undefined
   */

  function alpha2ToAlpha3(code) {
    return alpha2[code];
  }

  /*
   * @param code Alpha-3 code
   * @return Numeric code or undefined
   */

  function alpha3ToNumeric(code) {
    return invertedNumeric[alpha3ToAlpha2(code)];
  }

  /*
   * @param code Alpha-2 code
   * @return Numeric code or undefined
   */

  function alpha2ToNumeric(code) {
    return invertedNumeric[code];
  }

  /*
   * @param code Numeric code
   * @return Alpha-3 code or undefined
   */

  function numericToAlpha3(code) {
    return alpha2ToAlpha3(numeric[parseInt(code, 10)]);
  }

  /*
   * @param code Numeric code
   * @return Alpha-2 code or undefined
   */

  function numericToAlpha2(code) {
    return numeric[parseInt(code, 10)];
  }

  /*
   * @param code ISO 3166-1 alpha-2, alpha-3 or numeric code
   * @return ISO 3166-1 alpha-3
   */

  function toAlpha3(code) {
    if (typeof code === "string") {
      if (/^[0-9]*$/.test(code)) {
        return numericToAlpha3(code);
      }
      if (code.length === 2) {
        return alpha2ToAlpha3(code.toUpperCase());
      }
      if (code.length === 3) {
        return code.toUpperCase();
      }
    }
    if (typeof code === "number") {
      return numericToAlpha3(code);
    }
    return undefined;
  }

  /*
   * @param code ISO 3166-1 alpha-2, alpha-3 or numeric code
   * @return ISO 3166-1 alpha-2
   */

  function toAlpha2(code) {
    if (typeof code === "string") {
      if (/^[0-9]*$/.test(code)) {
        return numericToAlpha2(code);
      }
      if (code.length === 2) {
        return code.toUpperCase();
      }
      if (code.length === 3) {
        return alpha3ToAlpha2(code.toUpperCase());
      }
    }
    if (typeof code === "number") {
      return numericToAlpha2(code);
    }
    return undefined;
  }

  /*
   * @param code ISO 3166-1 alpha-2, alpha-3 or numeric code
   * @param lang language for country name
   * @return name or undefined
   */

  function getName(code, lang) {
    try {
      var l = langs[lang.toLowerCase()];
      return l[toAlpha2(code)];
    } catch (err) {
      return undefined;
    }
  }

  /*
   * @param lang language for country name
   * @return hash
   */

  function getNames(lang) {
    return langs[lang.toLowerCase()] || {};
  }

  /*
   * @param name name
   * @param lang language for country name
   * @return ISO 3166-1 alpha-2 or undefined
   */

  function getAlpha2Code(name, lang) {
    try {
      var p = undefined;
      var codenames = langs[lang.toLowerCase()];
      for (p in codenames) {
        if (codenames.hasOwnProperty(p)) {
          if (codenames[p].toLowerCase() === name.toLowerCase()) {
            return p;
          }
        }
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  }

  /*
   * @return hash (alpha-2 => alpha-3)
   */

  function getAlpha2Codes() {
    return alpha2;
  }

  /*
   * @return hash (alpha-3 => alpha-2)
   */

  function getAlpha3Codes() {
    return alpha3;
  }

  /*
   * @return hash (numeric => alpha-2)
   */

  function getNumericCodes() {
    return numeric;
  }
});