define("ember-i18n-iso-countries/langs/nl", ["exports"], function (exports) {
  exports["default"] = {
    "AF": "Afghanistan",
    "AL": "Albanië",
    "DZ": "Algerije",
    "AS": "Amerikaans-Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua en Barbuda",
    "AR": "Argentinië",
    "AM": "Armenië",
    "AW": "Aruba",
    "AU": "Australië",
    "AT": "Oostenrijk",
    "AZ": "Azerbeidzjan",
    "BS": "Bahama's",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Wit-Rusland",
    "BE": "België",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivië",
    "BA": "Bosnië-Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Eiland",
    "BR": "Brazilië",
    "IO": "Brits Indische oceaan",
    "BN": "Brunei Darussalam",
    "BG": "Bulgarije",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodja",
    "CM": "Kameroen",
    "CA": "Canada",
    "CV": "Kaapverdië",
    "KY": "Kaaimaneilanden",
    "CF": "Centraal-Afrikaanse Republiek",
    "TD": "Tsjaad",
    "CL": "Chili",
    "CN": "China",
    "CX": "Christmaseiland",
    "CC": "Cocoseilanden",
    "CO": "Colombia",
    "KM": "Comoren",
    "CG": "Congo, Volksrepubliek",
    "CD": "Congo, Democratische Republiek",
    "CK": "Cookeilanden",
    "CR": "Costa Rica",
    "CI": "Ivoorkust",
    "HR": "Kroatië",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Tsjechië",
    "DK": "Denemarken",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominicaanse Republiek",
    "EC": "Ecuador",
    "EG": "Egypte",
    "SV": "El Salvador",
    "GQ": "Equatoriaal-Guinea",
    "ER": "Eritrea",
    "EE": "Estland",
    "ET": "Ethiopië",
    "FK": "Falklandeilanden",
    "FO": "Faeröer",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "Frankrijk",
    "GF": "Frans-Guyana",
    "PF": "Frans-Polynesië",
    "TF": "Franse Zuidelijke Gebieden",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgië",
    "DE": "Duitsland",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Griekenland",
    "GL": "Groenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinee-Bissau",
    "GY": "Guyana",
    "HT": "Haïti",
    "HM": "Heard en McDonaldeilanden",
    "VA": "Heilige Stoel",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hongarije",
    "IS": "IJsland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Ierland",
    "IL": "Israël",
    "IT": "Italië",
    "JM": "Jamaica",
    "JP": "Japan",
    "JO": "Jordanië",
    "KZ": "Kazachstan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Noord-Korea, Democratische Volksrepubliek",
    "KR": "Zuid-Korea",
    "KW": "Koeweit",
    "KG": "Kirgizstan",
    "LA": "Laos",
    "LV": "Letland",
    "LB": "Libanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libië",
    "LI": "Liechtenstein",
    "LT": "Litouwen",
    "LU": "Luxemburg, Groot-Hertogdom",
    "MO": "Macao",
    "MK": "Macedonië, Ex-Joegoslavische Republiek",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Maleisië",
    "MV": "Maldiven",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshalleilanden",
    "MQ": "Martinique",
    "MR": "Mauritanië",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesië, Federale Staten",
    "MD": "Moldavië",
    "MC": "Monaco",
    "MN": "Mongolië",
    "MS": "Montserrat",
    "MA": "Marokko",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibië",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Nederland",
    "NC": "Nieuw-Caledonië",
    "NZ": "Nieuw-Zeeland",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk",
    "MP": "Noordelijke Marianen",
    "NO": "Noorwegen",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panama",
    "PG": "Papoea-Nieuw-Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Filipijnen",
    "PN": "Pitcairn",
    "PL": "Polen",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Roemenië",
    "RU": "Rusland",
    "RW": "Rwanda",
    "SH": "Sint-Helena",
    "KN": "Saint Kitts en Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint-Pierre en Miquelon",
    "VC": "Saint Vincent en de Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé en Principe",
    "SA": "Saudi-Arabië",
    "SN": "Senegal",
    "SC": "Seychellen",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slowakije",
    "SI": "Slovenië",
    "SB": "Salomonseilanden",
    "SO": "Somalië",
    "ZA": "Zuid-Afrika",
    "GS": "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    "ES": "Spanje",
    "LK": "Sri Lanka",
    "SD": "Soedan",
    "SR": "Suriname",
    "SJ": "Spitsbergen en Jan Mayen",
    "SZ": "Ngwane, Koninkrijk Swaziland",
    "SE": "Zweden",
    "CH": "Zwitserland",
    "SY": "Syrië",
    "TW": "Taiwan",
    "TJ": "Tadzjikistan",
    "TZ": "Tanzania, Verenigde Republiek",
    "TH": "Thailand",
    "TL": "Timor Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad en Tobago",
    "TN": "Tunesië",
    "TR": "Turkije",
    "TM": "Turkmenistan",
    "TC": "Turks- en Caicoseilanden",
    "TV": "Tuvalu",
    "UG": "Oeganda",
    "UA": "Oekraïne",
    "AE": "Verenigde Arabische Emiraten",
    "GB": "Verenigd Koninkrijk",
    "US": "Verenigde Staten van Amerika",
    "UM": "Ver afgelegen eilandjes van de Verenigde Staten",
    "UY": "Uruguay",
    "UZ": "Oezbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Maagdeneilanden, Britse",
    "VI": "Maagdeneilanden, Amerikaanse",
    "WF": "Wallis en Futuna",
    "EH": "Westelijke Sahara",
    "YE": "Jemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland",
    "BQ": "Bonaire, Sint Eustatius en Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Eiland Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Sint-Maarten (Frans deel)",
    "RS": "Servië",
    "SX": "Sint Maarten (Nederlands deel)",
    "SS": "Zuid-Soedan"
  };
});