define('ember-simple-auth-auth0/utils/errors', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Auth0Error = Auth0Error;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var EmberError = _ember.default.Error;
  function Auth0Error(payload) {
    var message = 'Auth0 operation failed';

    if ((typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && payload !== null) {
      if (payload.errorDescription) {
        payload.errorDescription = decodeURI(payload.errorDescription);
      }
      var errorCode = payload.error || 'unknown';
      var errorDesc = payload.errorDescription || message;
      message = 'Auth0 returned error `' + errorCode + '`: ' + errorDesc;
    } else if (typeof payload === 'string') {
      message += ': ' + payload;
      payload = {};
    }

    EmberError.call(this, message);
    this.payload = payload;
  }

  Auth0Error.prototype = Object.create(EmberError.prototype);
});