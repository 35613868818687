define('ember-simple-auth-auth0/authenticators/auth0-url-hash', ['exports', 'ember', 'ember-simple-auth-auth0/authenticators/auth0-base', 'ember-simple-auth-auth0/utils/create-session-data-object', 'ember-simple-auth-auth0/utils/errors'], function (exports, _ember, _auth0Base, _createSessionDataObject, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      get = _ember.default.get,
      service = _ember.default.inject.service,
      isEmpty = _ember.default.isEmpty;
  exports.default = _auth0Base.default.extend({
    auth0: service(),
    session: service(),
    authenticate: function authenticate(urlHashData) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        if (isEmpty(urlHashData)) {
          reject();
        }
        var auth0 = get(_this, 'auth0').getAuth0Instance();
        var getUserInfo = auth0.client.userInfo.bind(auth0.client);

        getUserInfo(urlHashData.accessToken, function (err, profile) {
          if (err) {
            return reject(new _errors.Auth0Error(err));
          }

          resolve((0, _createSessionDataObject.default)(profile, urlHashData));
        });
      });
    }
  });
});