define('ember-semantic-ui-calendar/components/ui-calendar', ['exports', 'ember', 'semantic-ui-ember/mixins/base', 'ember-semantic-ui-calendar/templates/components/ui-calendar'], function (exports, _ember, _semanticUiEmberMixinsBase, _emberSemanticUiCalendarTemplatesComponentsUiCalendar) {
  var Component = _ember['default'].Component;

  /**
   * @class UiCalendar
   * @extends Ember.Component
   * @namespace Semantic
   * @see https://github.com/mdehoog/Semantic-UI-Calendar
   */
  exports['default'] = Component.extend(_semanticUiEmberMixinsBase['default'], {
    layout: _emberSemanticUiCalendarTemplatesComponentsUiCalendar['default'],

    module: 'calendar',
    classNames: ['ui', 'calendar'],

    /**
     * Name of an icon to display in the input. You can pass `false` to not show an icon.
     *
     * @property icon
     * @type String|Boolean
     * @default 'calendar'
     * @public
     * @see http://semantic-ui.com/elements/icon.html
     */
    icon: 'calendar',

    /**
     * Placeholder for the input.
     *
     * @property placeholder
     * @type String
     * @default ''
     * @public
     */
    placeholder: '',

    getSemanticIgnorableAttrs: function getSemanticIgnorableAttrs() {
      return ['icon', 'placeholder'];
    },

    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);

      var date = this.get('date');
      if (date) {
        this.$().calendar('set date', date);
      }
    }
  });
});