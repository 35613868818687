define('ember-composable-helpers/helpers/object-at', ['exports', 'ember-helper', 'ember-array/utils', 'ember-computed', 'ember-metal/observer', 'ember-metal/get', 'ember-metal/set'], function (exports, _emberHelper, _utils, _emberComputed, _observer, _get, _set) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.objectAt = objectAt;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function objectAt(index, array) {
    if (!(0, _utils.isEmberArray)(array)) {
      return undefined;
    }

    index = parseInt(index, 10);

    return (0, _utils.A)(array).objectAt(index);
  }

  exports.default = _emberHelper.default.extend({
    content: (0, _emberComputed.default)('index', 'array.[]', function () {
      var index = (0, _get.default)(this, 'index');
      var array = (0, _get.default)(this, 'array');

      return objectAt(index, array);
    }),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          index = _ref2[0],
          array = _ref2[1];

      (0, _set.default)(this, 'index', index);
      (0, _set.default)(this, 'array', array);

      return (0, _get.default)(this, 'content');
    },


    contentDidChange: (0, _observer.default)('content', function () {
      this.recompute();
    })
  });
});