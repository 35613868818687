define('ember-math-helpers/helpers/sign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sign = sign;
  var helper = Ember.Helper.helper;
  function sign(params) {
    return Math.sign(params[0]);
  }

  exports.default = helper(sign);
});