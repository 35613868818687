define('ember-steps/-private/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MissingPropertyError = MissingPropertyError;
  exports.StepNameError = StepNameError;
  function createErrorMessage(missingProperty) {
    return '`' + missingProperty + '` must be provided as static value.\n\nIf you\'re seeing this message, you\'re likely either iterating over some data\nto create your steps or doing something to provide the value dynamically. This\nis currently unsupported by `ember-steps`. Please see the following for more\ninformation:\n\nhttps://github.com/alexlafroscia/ember-steps/wiki/dynamically-generating-steps';
  }

  /**
   * @class MissingPropertyError
   * @private
   */
  function MissingPropertyError(missingProperty) {
    this.name = 'MissingPropertyError';
    this.stack = new Error().stack;
    this.message = createErrorMessage(missingProperty);
  }
  MissingPropertyError.prototype = new Error();

  /**
   * @class StepNameError
   * @private
   */
  function StepNameError(message) {
    this.name = 'StepNameError';
    this.message = message;
    this.stack = new Error().stack;
  }
  StepNameError.prototype = new Error();
});