define('ember-simple-auth-auth0/services/auth0', ['exports', 'ember', 'auth0', 'auth0-lock', 'ember-simple-auth-auth0/utils/create-session-data-object', 'ember-simple-auth-auth0/utils/errors'], function (exports, _ember, _auth, _auth0Lock, _createSessionDataObject, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      computed = _ember.default.computed,
      readOnly = _ember.default.computed.readOnly,
      _get = _ember.default.get,
      getOwner = _ember.default.getOwner,
      getProperties = _ember.default.getProperties,
      assert = _ember.default.assert,
      isEmpty = _ember.default.isEmpty,
      service = _ember.default.inject.service,
      RSVP = _ember.default.RSVP;
  exports.default = Service.extend({
    session: service(),
    /**
     * The env config found in the environment config.
     * ENV['ember-simple-auth'].auth0
     *
     * @type {Object}
     */
    config: computed({
      get: function get() {
        var emberSimpleAuthConfig = _get(this, '_environmentConfig')['ember-simple-auth'];
        assert('ember-simple-auth config must be defined', emberSimpleAuthConfig);
        assert('ember-simple-auth.auth0 config must be defined', emberSimpleAuthConfig.auth0);

        return emberSimpleAuthConfig.auth0;
      }
    }),

    /**
     * The Auth0 App ClientID found in your Auth0 dashboard
     * @type {String}
     */
    clientID: readOnly('config.clientID'),

    /**
     * The Auth0 App Domain found in your Auth0 dashboard
     * @type {String}
     */
    domain: readOnly('config.domain'),

    logoutReturnToURL: readOnly('config.logoutReturnToURL'),

    showLock: function showLock(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var _this = this;

      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var passwordless = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      return new RSVP.Promise(function (resolve, reject) {
        var lock = _this.getAuth0LockInstance(options, clientID, domain, passwordless);
        _this._setupLock(lock, resolve, reject);
        lock.show();
      });
    },
    showPasswordlessLock: function showPasswordlessLock(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      return this.showLock(options, clientID, domain, true);
    },
    _setupLock: function _setupLock(lock, resolve, reject) {
      lock.on('unrecoverable_error', reject);
      lock.on('authorization_error', reject);

      // lock.on('hash_parsed', resolve);
      lock.on('authenticated', function (authenticatedData) {
        if (isEmpty(authenticatedData)) {
          return reject(new _errors.Auth0Error('The authenticated data did not come back from the request'));
        }

        lock.getUserInfo(authenticatedData.accessToken, function (error, profile) {
          if (error) {
            return reject(new _errors.Auth0Error(error));
          }

          resolve((0, _createSessionDataObject.default)(profile, authenticatedData));
        });
      });
    },
    getAuth0LockInstance: function getAuth0LockInstance(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var passwordless = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      clientID = clientID || _get(this, 'clientID');
      domain = domain || _get(this, 'domain');
      var Auth0LockConstructor = _get(this, passwordless ? '_auth0LockPasswordless' : '_auth0Lock');

      return new Auth0LockConstructor(clientID, domain, options);
    },
    getAuth0Instance: function getAuth0Instance() {
      var clientID = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var domain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      clientID = clientID || _get(this, 'clientID');
      domain = domain || _get(this, 'domain');

      var Auth0Constructor = _get(this, '_auth0.WebAuth');

      return new Auth0Constructor({
        domain: domain,
        clientID: clientID
      });
    },
    getAuth0LockPasswordlessInstance: function getAuth0LockPasswordlessInstance(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      return this.getAuth0LockInstance(options, clientID, domain, true);
    },
    navigateToLogoutURL: function navigateToLogoutURL() {
      var _getProperties = getProperties(this, 'domain', 'logoutReturnToURL', 'clientID'),
          domain = _getProperties.domain,
          logoutReturnToURL = _getProperties.logoutReturnToURL,
          clientID = _getProperties.clientID;

      if (!_ember.default.testing) {
        window.location.replace('https://' + domain + '/v2/logout?returnTo=' + logoutReturnToURL + '&client_id=' + clientID);
      }
    },
    logout: function logout() {
      _get(this, 'session').invalidate().then(this.navigateToLogoutURL.bind(this));
    },


    _auth0: computed(function () {
      return _auth.default;
    }),

    _auth0Lock: computed(function () {
      return _auth0Lock.Auth0Lock;
    }),

    _auth0LockPasswordless: computed(function () {
      return _auth0Lock.Auth0LockPasswordless;
    }),

    _environmentConfig: computed({
      get: function get() {
        return getOwner(this).resolveRegistration('config:environment');
      }
    })
  });
});