define('ember-simple-auth-auth0/mixins/application-route-mixin', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth-auth0/utils/errors', 'ember-simple-auth-auth0/utils/get-session-expiration', 'ember-simple-auth-auth0/utils/now'], function (exports, _ember, _applicationRouteMixin, _errors, _getSessionExpiration, _now) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      computed = _ember.default.computed,
      _get = _ember.default.get,
      getWithDefault = _ember.default.getWithDefault,
      set = _ember.default.set,
      RSVP = _ember.default.RSVP,
      resolve = _ember.default.RSVP.resolve,
      service = _ember.default.inject.service,
      run = _ember.default.run,
      isEmpty = _ember.default.isEmpty;
  exports.default = Mixin.create(_applicationRouteMixin.default, {
    session: service(),
    auth0: service(),

    sessionAuthenticated: function sessionAuthenticated() {
      this._setupFutureEvents();
      this._super.apply(this, arguments);
    },


    /**
     * Hook that gets called after the jwt has expired
     * but before we notify the rest of the system.
     * Great place to add cleanup to expire any third-party
     * tokens or other cleanup.
     *
     * IMPORTANT: You must return a promise, else logout
     * will not continue.
     *
     * @return {Promise}
     */
    beforeSessionExpired: function beforeSessionExpired() {
      return resolve();
    },


    /**
     * This has to be overridden because the default behavior prevents
     * auth0 to logout correctly.
     */
    sessionInvalidated: function sessionInvalidated() {
      this._clearJobs();
      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      this._setupFutureEvents();
      var promise = resolve(this._super.apply(this, arguments));

      promise = promise.then(this._getUrlHashData.bind(this)).then(this._authenticateWithUrlHash.bind(this));

      return promise;
    },
    willDestroy: function willDestroy() {
      this._clearJobs();
    },
    _authenticateWithUrlHash: function _authenticateWithUrlHash(urlHashData) {
      if (isEmpty(urlHashData)) {
        return;
      }

      return _get(this, 'session').authenticate('authenticator:auth0-url-hash', urlHashData).then(this._clearUrlHash.bind(this));
    },
    _getUrlHashData: function _getUrlHashData() {
      var auth0 = _get(this, 'auth0').getAuth0Instance();
      var enableImpersonation = !!_get(this, 'auth0.config.enableImpersonation');
      return new RSVP.Promise(function (resolve, reject) {
        auth0.parseHash({ __enableImpersonation: enableImpersonation }, function (err, parsedPayload) {
          if (err) {
            return reject(new _errors.Auth0Error(err));
          }

          resolve(parsedPayload);
        });
      });
    },
    _clearUrlHash: function _clearUrlHash() {
      if (!_ember.default.testing && window.history) {
        window.history.pushState('', document.title, window.location.pathname + window.location.search);
      }
      return RSVP.resolve();
    },
    _setupFutureEvents: function _setupFutureEvents() {
      // Don't schedule expired events during testing, otherwise acceptance tests will hang.
      if (_ember.default.testing) {
        return;
      }

      this._scheduleExpire();
    },
    _scheduleExpire: function _scheduleExpire() {
      run.cancel(_get(this, '_expireJob'));
      var expireInMilli = _get(this, '_jwtRemainingTimeInSeconds') * 1000;
      var job = run.later(this, this._processSessionExpired, expireInMilli);
      set(this, '_expireJob', job);
    },


    /**
     * The current JWT's expire time
     * @return {Number in seconds}
     */
    _expiresAt: computed('session.data.authenticated', {
      get: function get() {
        if (!_get(this, 'session.isAuthenticated')) {
          return 0;
        }

        var sessionData = _get(this, 'session.data.authenticated');
        return (0, _getSessionExpiration.default)(sessionData);
      }
    }),

    _jwtRemainingTimeInSeconds: computed('_expiresAt', {
      get: function get() {
        var remaining = getWithDefault(this, '_expiresAt', 0) - (0, _now.default)();

        return remaining < 0 ? 0 : remaining;
      }
    }),

    _clearJobs: function _clearJobs() {
      run.cancel(_get(this, '_expireJob'));
    },
    _processSessionExpired: function _processSessionExpired() {
      return this.beforeSessionExpired().then(this._invalidateIfAuthenticated.bind(this));
    },
    _invalidateIfAuthenticated: function _invalidateIfAuthenticated() {
      var session = _get(this, 'session');

      if (_get(session, 'isAuthenticated')) {
        session.invalidate();
      }
    }
  });
});