define('ember-model-validator/initializers/model-locale', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var validatorDefaultLocale = application.get('validatorDefaultLocale');
    application.register('validator:locale', validatorDefaultLocale, { instantiate: false });
  }

  exports['default'] = {
    name: 'model-locale',
    initialize: initialize
  };
});