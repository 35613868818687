define("ember-simple-auth-auth0/utils/now", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = now;
  function now() {
    return Math.ceil(Date.now() / 1000);
  }
});