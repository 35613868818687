define('ember-composable-helpers/helpers/contains', ['exports', 'ember-array/utils', 'ember-metal/get', 'ember-composable-helpers/-private/create-needle-haystack-helper', 'ember-composable-helpers/utils/includes'], function (exports, _utils, _get, _createNeedleHaystackHelper, _includes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;


  function _contains(needle, haystack) {
    return (0, _includes.default)((0, _utils.A)(haystack), needle);
  }

  function contains(needle, haystack) {
    if (!(0, _utils.isEmberArray)(haystack)) {
      return false;
    }

    if ((0, _utils.isEmberArray)(needle) && (0, _get.default)(needle, 'length')) {
      return needle.reduce(function (acc, val) {
        return acc && _contains(val, haystack);
      }, true);
    }

    return _contains(needle, haystack);
  }

  exports.default = (0, _createNeedleHaystackHelper.default)(contains);
});