define('ember-composable-helpers/utils/get-index', ['exports', 'ember-array/utils', 'ember-composable-helpers/utils/is-equal'], function (exports, _utils, _isEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getIndex;
  function getIndex(array, currentValue, useDeepEqual) {
    var needle = currentValue;

    if (useDeepEqual) {
      needle = (0, _utils.A)(array).find(function (object) {
        return (0, _isEqual.default)(object, currentValue, useDeepEqual);
      });
    }

    var index = (0, _utils.A)(array).indexOf(needle);

    return index >= 0 ? index : null;
  }
});