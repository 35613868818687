define('ember-math-helpers/helpers/div', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.div = div;
  var helper = Ember.Helper.helper;
  function div(params) {
    return params.reduce(function (a, b) {
      return Number(a) / Number(b);
    });
  }

  exports.default = helper(div);
});