define(
    "d3-time-format",
    ["exports", "d3-time"],
    function (exports,d3Time) { 'use strict';

      function localDate(d) {
        if (0 <= d.y && d.y < 100) {
          var date = new Date(-1, d.m, d.d, d.H, d.M, d.S, d.L);
          date.setFullYear(d.y);
          return date;
        }
        return new Date(d.y, d.m, d.d, d.H, d.M, d.S, d.L);
      }

      function utcDate(d) {
        if (0 <= d.y && d.y < 100) {
          var date = new Date(Date.UTC(-1, d.m, d.d, d.H, d.M, d.S, d.L));
          date.setUTCFullYear(d.y);
          return date;
        }
        return new Date(Date.UTC(d.y, d.m, d.d, d.H, d.M, d.S, d.L));
      }

      function newYear(y) {
        return {y: y, m: 0, d: 1, H: 0, M: 0, S: 0, L: 0};
      }

      function formatLocale(locale) {
        var locale_dateTime = locale.dateTime,
            locale_date = locale.date,
            locale_time = locale.time,
            locale_periods = locale.periods,
            locale_weekdays = locale.days,
            locale_shortWeekdays = locale.shortDays,
            locale_months = locale.months,
            locale_shortMonths = locale.shortMonths;

        var periodRe = formatRe(locale_periods),
            periodLookup = formatLookup(locale_periods),
            weekdayRe = formatRe(locale_weekdays),
            weekdayLookup = formatLookup(locale_weekdays),
            shortWeekdayRe = formatRe(locale_shortWeekdays),
            shortWeekdayLookup = formatLookup(locale_shortWeekdays),
            monthRe = formatRe(locale_months),
            monthLookup = formatLookup(locale_months),
            shortMonthRe = formatRe(locale_shortMonths),
            shortMonthLookup = formatLookup(locale_shortMonths);

        var formats = {
          "a": formatShortWeekday,
          "A": formatWeekday,
          "b": formatShortMonth,
          "B": formatMonth,
          "c": null,
          "d": formatDayOfMonth,
          "e": formatDayOfMonth,
          "H": formatHour24,
          "I": formatHour12,
          "j": formatDayOfYear,
          "L": formatMilliseconds,
          "m": formatMonthNumber,
          "M": formatMinutes,
          "p": formatPeriod,
          "S": formatSeconds,
          "U": formatWeekNumberSunday,
          "w": formatWeekdayNumber,
          "W": formatWeekNumberMonday,
          "x": null,
          "X": null,
          "y": formatYear,
          "Y": formatFullYear,
          "Z": formatZone,
          "%": formatLiteralPercent
        };

        var utcFormats = {
          "a": formatUTCShortWeekday,
          "A": formatUTCWeekday,
          "b": formatUTCShortMonth,
          "B": formatUTCMonth,
          "c": null,
          "d": formatUTCDayOfMonth,
          "e": formatUTCDayOfMonth,
          "H": formatUTCHour24,
          "I": formatUTCHour12,
          "j": formatUTCDayOfYear,
          "L": formatUTCMilliseconds,
          "m": formatUTCMonthNumber,
          "M": formatUTCMinutes,
          "p": formatUTCPeriod,
          "S": formatUTCSeconds,
          "U": formatUTCWeekNumberSunday,
          "w": formatUTCWeekdayNumber,
          "W": formatUTCWeekNumberMonday,
          "x": null,
          "X": null,
          "y": formatUTCYear,
          "Y": formatUTCFullYear,
          "Z": formatUTCZone,
          "%": formatLiteralPercent
        };

        var parses = {
          "a": parseShortWeekday,
          "A": parseWeekday,
          "b": parseShortMonth,
          "B": parseMonth,
          "c": parseLocaleDateTime,
          "d": parseDayOfMonth,
          "e": parseDayOfMonth,
          "H": parseHour24,
          "I": parseHour24,
          "j": parseDayOfYear,
          "L": parseMilliseconds,
          "m": parseMonthNumber,
          "M": parseMinutes,
          "p": parsePeriod,
          "S": parseSeconds,
          "U": parseWeekNumberSunday,
          "w": parseWeekdayNumber,
          "W": parseWeekNumberMonday,
          "x": parseLocaleDate,
          "X": parseLocaleTime,
          "y": parseYear,
          "Y": parseFullYear,
          "Z": parseZone,
          "%": parseLiteralPercent
        };

        // These recursive directive definitions must be deferred.
        formats.x = newFormat(locale_date, formats);
        formats.X = newFormat(locale_time, formats);
        formats.c = newFormat(locale_dateTime, formats);
        utcFormats.x = newFormat(locale_date, utcFormats);
        utcFormats.X = newFormat(locale_time, utcFormats);
        utcFormats.c = newFormat(locale_dateTime, utcFormats);

        function newFormat(specifier, formats) {
          return function(date) {
            var string = [],
                i = -1,
                j = 0,
                n = specifier.length,
                c,
                pad,
                format;

            if (!(date instanceof Date)) date = new Date(+date);

            while (++i < n) {
              if (specifier.charCodeAt(i) === 37) {
                string.push(specifier.slice(j, i));
                if ((pad = pads[c = specifier.charAt(++i)]) != null) c = specifier.charAt(++i);
                else pad = c === "e" ? " " : "0";
                if (format = formats[c]) c = format(date, pad);
                string.push(c);
                j = i + 1;
              }
            }

            string.push(specifier.slice(j, i));
            return string.join("");
          };
        }

        function newParse(specifier, newDate) {
          return function(string) {
            var d = newYear(1900),
                i = parseSpecifier(d, specifier, string += "", 0);
            if (i != string.length) return null;

            // The am-pm flag is 0 for AM, and 1 for PM.
            if ("p" in d) d.H = d.H % 12 + d.p * 12;

            // Convert day-of-week and week-of-year to day-of-year.
            if ("W" in d || "U" in d) {
              if (!("w" in d)) d.w = "W" in d ? 1 : 0;
              var day = "Z" in d ? utcDate(newYear(d.y)).getUTCDay() : newDate(newYear(d.y)).getDay();
              d.m = 0;
              d.d = "W" in d ? (d.w + 6) % 7 + d.W * 7 - (day + 5) % 7 : d.w + d.U * 7 - (day + 6) % 7;
            }

            // If a time zone is specified, all fields are interpreted as UTC and then
            // offset according to the specified time zone.
            if ("Z" in d) {
              d.H += d.Z / 100 | 0;
              d.M += d.Z % 100;
              return utcDate(d);
            }

            // Otherwise, all fields are in local time.
            return newDate(d);
          };
        }

        function parseSpecifier(d, specifier, string, j) {
          var i = 0,
              n = specifier.length,
              m = string.length,
              c,
              parse;

          while (i < n) {
            if (j >= m) return -1;
            c = specifier.charCodeAt(i++);
            if (c === 37) {
              c = specifier.charAt(i++);
              parse = parses[c in pads ? specifier.charAt(i++) : c];
              if (!parse || ((j = parse(d, string, j)) < 0)) return -1;
            } else if (c != string.charCodeAt(j++)) {
              return -1;
            }
          }

          return j;
        }

        function parsePeriod(d, string, i) {
          var n = periodRe.exec(string.slice(i));
          return n ? (d.p = periodLookup[n[0].toLowerCase()], i + n[0].length) : -1;
        }

        function parseShortWeekday(d, string, i) {
          var n = shortWeekdayRe.exec(string.slice(i));
          return n ? (d.w = shortWeekdayLookup[n[0].toLowerCase()], i + n[0].length) : -1;
        }

        function parseWeekday(d, string, i) {
          var n = weekdayRe.exec(string.slice(i));
          return n ? (d.w = weekdayLookup[n[0].toLowerCase()], i + n[0].length) : -1;
        }

        function parseShortMonth(d, string, i) {
          var n = shortMonthRe.exec(string.slice(i));
          return n ? (d.m = shortMonthLookup[n[0].toLowerCase()], i + n[0].length) : -1;
        }

        function parseMonth(d, string, i) {
          var n = monthRe.exec(string.slice(i));
          return n ? (d.m = monthLookup[n[0].toLowerCase()], i + n[0].length) : -1;
        }

        function parseLocaleDateTime(d, string, i) {
          return parseSpecifier(d, locale_dateTime, string, i);
        }

        function parseLocaleDate(d, string, i) {
          return parseSpecifier(d, locale_date, string, i);
        }

        function parseLocaleTime(d, string, i) {
          return parseSpecifier(d, locale_time, string, i);
        }

        function formatShortWeekday(d) {
          return locale_shortWeekdays[d.getDay()];
        }

        function formatWeekday(d) {
          return locale_weekdays[d.getDay()];
        }

        function formatShortMonth(d) {
          return locale_shortMonths[d.getMonth()];
        }

        function formatMonth(d) {
          return locale_months[d.getMonth()];
        }

        function formatPeriod(d) {
          return locale_periods[+(d.getHours() >= 12)];
        }

        function formatUTCShortWeekday(d) {
          return locale_shortWeekdays[d.getUTCDay()];
        }

        function formatUTCWeekday(d) {
          return locale_weekdays[d.getUTCDay()];
        }

        function formatUTCShortMonth(d) {
          return locale_shortMonths[d.getUTCMonth()];
        }

        function formatUTCMonth(d) {
          return locale_months[d.getUTCMonth()];
        }

        function formatUTCPeriod(d) {
          return locale_periods[+(d.getUTCHours() >= 12)];
        }

        return {
          format: function(specifier) {
            var f = newFormat(specifier += "", formats);
            f.toString = function() { return specifier; };
            return f;
          },
          parse: function(specifier) {
            var p = newParse(specifier += "", localDate);
            p.toString = function() { return specifier; };
            return p;
          },
          utcFormat: function(specifier) {
            var f = newFormat(specifier += "", utcFormats);
            f.toString = function() { return specifier; };
            return f;
          },
          utcParse: function(specifier) {
            var p = newParse(specifier, utcDate);
            p.toString = function() { return specifier; };
            return p;
          }
        };
      }

      var pads = {"-": "", "_": " ", "0": "0"};
      var numberRe = /^\s*\d+/;
      var percentRe = /^%/;
      var requoteRe = /[\\\^\$\*\+\?\|\[\]\(\)\.\{\}]/g;
      function pad(value, fill, width) {
        var sign = value < 0 ? "-" : "",
            string = (sign ? -value : value) + "",
            length = string.length;
        return sign + (length < width ? new Array(width - length + 1).join(fill) + string : string);
      }

      function requote(s) {
        return s.replace(requoteRe, "\\$&");
      }

      function formatRe(names) {
        return new RegExp("^(?:" + names.map(requote).join("|") + ")", "i");
      }

      function formatLookup(names) {
        var map = {}, i = -1, n = names.length;
        while (++i < n) map[names[i].toLowerCase()] = i;
        return map;
      }

      function parseWeekdayNumber(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 1));
        return n ? (d.w = +n[0], i + n[0].length) : -1;
      }

      function parseWeekNumberSunday(d, string, i) {
        var n = numberRe.exec(string.slice(i));
        return n ? (d.U = +n[0], i + n[0].length) : -1;
      }

      function parseWeekNumberMonday(d, string, i) {
        var n = numberRe.exec(string.slice(i));
        return n ? (d.W = +n[0], i + n[0].length) : -1;
      }

      function parseFullYear(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 4));
        return n ? (d.y = +n[0], i + n[0].length) : -1;
      }

      function parseYear(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.y = +n[0] + (+n[0] > 68 ? 1900 : 2000), i + n[0].length) : -1;
      }

      function parseZone(d, string, i) {
        var n = /^(Z)|([+-]\d\d)(?:\:?(\d\d))?/.exec(string.slice(i, i + 6));
        return n ? (d.Z = n[1] ? 0 : -(n[2] + (n[3] || "00")), i + n[0].length) : -1;
      }

      function parseMonthNumber(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.m = n[0] - 1, i + n[0].length) : -1;
      }

      function parseDayOfMonth(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.d = +n[0], i + n[0].length) : -1;
      }

      function parseDayOfYear(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 3));
        return n ? (d.m = 0, d.d = +n[0], i + n[0].length) : -1;
      }

      function parseHour24(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.H = +n[0], i + n[0].length) : -1;
      }

      function parseMinutes(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.M = +n[0], i + n[0].length) : -1;
      }

      function parseSeconds(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 2));
        return n ? (d.S = +n[0], i + n[0].length) : -1;
      }

      function parseMilliseconds(d, string, i) {
        var n = numberRe.exec(string.slice(i, i + 3));
        return n ? (d.L = +n[0], i + n[0].length) : -1;
      }

      function parseLiteralPercent(d, string, i) {
        var n = percentRe.exec(string.slice(i, i + 1));
        return n ? i + n[0].length : -1;
      }

      function formatDayOfMonth(d, p) {
        return pad(d.getDate(), p, 2);
      }

      function formatHour24(d, p) {
        return pad(d.getHours(), p, 2);
      }

      function formatHour12(d, p) {
        return pad(d.getHours() % 12 || 12, p, 2);
      }

      function formatDayOfYear(d, p) {
        return pad(1 + d3Time.timeDay.count(d3Time.timeYear(d), d), p, 3);
      }

      function formatMilliseconds(d, p) {
        return pad(d.getMilliseconds(), p, 3);
      }

      function formatMonthNumber(d, p) {
        return pad(d.getMonth() + 1, p, 2);
      }

      function formatMinutes(d, p) {
        return pad(d.getMinutes(), p, 2);
      }

      function formatSeconds(d, p) {
        return pad(d.getSeconds(), p, 2);
      }

      function formatWeekNumberSunday(d, p) {
        return pad(d3Time.timeSunday.count(d3Time.timeYear(d), d), p, 2);
      }

      function formatWeekdayNumber(d) {
        return d.getDay();
      }

      function formatWeekNumberMonday(d, p) {
        return pad(d3Time.timeMonday.count(d3Time.timeYear(d), d), p, 2);
      }

      function formatYear(d, p) {
        return pad(d.getFullYear() % 100, p, 2);
      }

      function formatFullYear(d, p) {
        return pad(d.getFullYear() % 10000, p, 4);
      }

      function formatZone(d) {
        var z = d.getTimezoneOffset();
        return (z > 0 ? "-" : (z *= -1, "+"))
            + pad(z / 60 | 0, "0", 2)
            + pad(z % 60, "0", 2);
      }

      function formatUTCDayOfMonth(d, p) {
        return pad(d.getUTCDate(), p, 2);
      }

      function formatUTCHour24(d, p) {
        return pad(d.getUTCHours(), p, 2);
      }

      function formatUTCHour12(d, p) {
        return pad(d.getUTCHours() % 12 || 12, p, 2);
      }

      function formatUTCDayOfYear(d, p) {
        return pad(1 + d3Time.utcDay.count(d3Time.utcYear(d), d), p, 3);
      }

      function formatUTCMilliseconds(d, p) {
        return pad(d.getUTCMilliseconds(), p, 3);
      }

      function formatUTCMonthNumber(d, p) {
        return pad(d.getUTCMonth() + 1, p, 2);
      }

      function formatUTCMinutes(d, p) {
        return pad(d.getUTCMinutes(), p, 2);
      }

      function formatUTCSeconds(d, p) {
        return pad(d.getUTCSeconds(), p, 2);
      }

      function formatUTCWeekNumberSunday(d, p) {
        return pad(d3Time.utcSunday.count(d3Time.utcYear(d), d), p, 2);
      }

      function formatUTCWeekdayNumber(d) {
        return d.getUTCDay();
      }

      function formatUTCWeekNumberMonday(d, p) {
        return pad(d3Time.utcMonday.count(d3Time.utcYear(d), d), p, 2);
      }

      function formatUTCYear(d, p) {
        return pad(d.getUTCFullYear() % 100, p, 2);
      }

      function formatUTCFullYear(d, p) {
        return pad(d.getUTCFullYear() % 10000, p, 4);
      }

      function formatUTCZone() {
        return "+0000";
      }

      function formatLiteralPercent() {
        return "%";
      }

      var locale;
      exports.timeFormat;
      exports.timeParse;
      exports.utcFormat;
      exports.utcParse;

      defaultLocale({
        dateTime: "%x, %X",
        date: "%-m/%-d/%Y",
        time: "%-I:%M:%S %p",
        periods: ["AM", "PM"],
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      });

      function defaultLocale(definition) {
        locale = formatLocale(definition);
        exports.timeFormat = locale.format;
        exports.timeParse = locale.parse;
        exports.utcFormat = locale.utcFormat;
        exports.utcParse = locale.utcParse;
        return locale;
      }

      var isoSpecifier = "%Y-%m-%dT%H:%M:%S.%LZ";

      function formatIsoNative(date) {
        return date.toISOString();
      }

      var formatIso = Date.prototype.toISOString
          ? formatIsoNative
          : exports.utcFormat(isoSpecifier);

      function parseIsoNative(string) {
        var date = new Date(string);
        return isNaN(date) ? null : date;
      }

      var parseIso = +new Date("2000-01-01T00:00:00.000Z")
          ? parseIsoNative
          : exports.utcParse(isoSpecifier);

      exports.timeFormatDefaultLocale = defaultLocale;
      exports.timeFormatLocale = formatLocale;
      exports.isoFormat = formatIso;
      exports.isoParse = parseIso;

      Object.defineProperty(exports, '__esModule', { value: true });

    }
);