define('@zestia/ember-simple-infinite-scroller/services/-infinite-scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    document: document,
    documentElement: document.documentElement
  });
});