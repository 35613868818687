define('ember-simple-auth-auth0/authenticators/auth0-lock-passwordless', ['exports', 'ember', 'ember-simple-auth-auth0/authenticators/auth0-base'], function (exports, _ember, _auth0Base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get,
      service = _ember.default.inject.service;
  exports.default = _auth0Base.default.extend({
    auth0: service(),
    authenticate: function authenticate(options) {
      return get(this, 'auth0').showPasswordlessLock(options);
    }
  });
});