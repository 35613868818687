define('ember-simple-auth-auth0/authenticators/auth0-base', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'ember-simple-auth-auth0/utils/get-session-expiration', 'ember-simple-auth-auth0/utils/now'], function (exports, _ember, _base, _getSessionExpiration, _now) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      service = _ember.default.inject.service;
  exports.default = _base.default.extend({
    auth0: service(),
    restore: function restore(data) {
      var expiresAt = (0, _getSessionExpiration.default)(data || {});
      if (expiresAt > (0, _now.default)()) {
        return RSVP.resolve(data);
      } else {
        return RSVP.reject();
      }
    }
  });
});