define('ember-flatpickr/components/ember-flatpickr', ['exports', 'ember-metal/utils', 'ember-platform', 'ember-component', 'ember-metal/observer', 'ember-evented/on', 'ember-runloop'], function (exports, _utils, _emberPlatform, _emberComponent, _observer, _on, _emberRunloop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberComponent.default.extend({
    tagName: 'input',
    type: 'text',
    attributeBindings: ['disabled', 'placeholder', 'type'],
    // Flatpickr options
    allowInput: false,
    altFormat: 'F j, Y',
    altInput: false,
    altInputClass: '',
    clickOpens: true,
    dateFormat: 'Y-m-d',
    defaultDate: null,
    defaultHour: 12,
    defaultMinute: 0,
    disable: [],
    disabled: false,
    disableMobile: false,
    enable: [],
    enableSeconds: false,
    enableTime: false,
    flatpickrRef: null,
    hourIncrement: 1,
    inline: false,
    locale: 'default',
    maxDate: null,
    minDate: null,
    minuteIncrement: 5,
    mode: 'single',
    nextArrow: '>',
    noCalendar: false,
    parseDate: false,
    prevArrow: '<',
    shorthandCurrentMonth: false,
    static: false,
    timeFormat: 'H:i',
    time_24hr: false, // eslint-disable-line camelcase
    utc: false,
    weekNumbers: false,
    wrap: false,

    setupComponent: (0, _on.default)('init', function () {
      // Require that users pass an onChange now
      (0, _utils.assert)('{{ember-flatpickr}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined);

      // Pass all values and setup flatpickr
      _emberRunloop.default.scheduleOnce('afterRender', this, function () {
        var options = this.getProperties(['allowInput', 'altFormat', 'altInput', 'altInputClass', 'clickOpens', 'dateFormat', 'defaultDate', 'defaultHour', 'defaultMinute', 'disable', 'disableMobile', 'enable', 'enableSeconds', 'enableTime', 'hourIncrement', 'inline', 'locale', 'maxDate', 'minDate', 'minuteIncrement', 'mode', 'nextArrow', 'noCalendar', 'parseDate', 'prevArrow', 'shorthandCurrentMonth', 'static', 'timeFormat', 'time_24hr', 'utc', 'value', 'weekNumbers', 'wrap']);

        // Add defaultDate, change and close handlers
        (0, _emberPlatform.assign)(options, {
          defaultDate: this.get('value'),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });

        var flatpickrRef = flatpickr(this.element, options);

        if (this.get('appendDataInput')) {
          this.$().attr('data-input', '');
        }
        this.set('flatpickrRef', flatpickrRef);
      });
    }),

    localeUpdated: (0, _observer.default)('locale', function () {
      this.element._flatpickr.destroy();
      this.setupComponent();
    }),
    maxDateUpdated: (0, _observer.default)('maxDate', function () {
      this.element._flatpickr.set('maxDate', this.get('maxDate'));
    }),
    minDateUpdated: (0, _observer.default)('minDate', function () {
      this.element._flatpickr.set('minDate', this.get('minDate'));
    }),
    valueUpdated: (0, _observer.default)('value', function () {
      var value = this.get('value');
      var ref = this.element._flatpickr;

      if (ref && typeof value !== 'undefined') {
        ref.setDate(value);
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this.element._flatpickr.destroy();
    },


    /**
     * When the date is changed, update the value and send 'onChange' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onChange: function _onChange(selectedDates, dateStr, instance) {
      this.sendAction('onChange', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is closed, fire the 'onClose' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onClose: function _onClose(selectedDates, dateStr, instance) {
      this.sendAction('onClose', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is opened, fire the 'onOpen' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onOpen: function _onOpen(selectedDates, dateStr, instance) {
      this.sendAction('onOpen', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is ready, fire the 'onReady' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onReady: function _onReady(selectedDates, dateStr, instance) {
      this.sendAction('onReady', selectedDates, dateStr, instance);
    }
  });
});